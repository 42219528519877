<template>
  <div>
    <v-card>
      <v-card-title class="px-2">
        <Comeback />  {{ $t("report_profit_loss_summary") }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="d-none d-md-block"
          :loading="exportLoading"
          :disabled="exportLoading"
          @click="exportExcel"
        >
          {{ $t('excel') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-block d-md-none"
          :loading="exportLoading"
          :disabled="exportLoading"
          icon
          fab
          outlined
          @click="exportExcel"
        >
          <v-icon>{{ mdiFileExcelOutline }}</v-icon>
        </v-btn>
      </v-card-title>
      <DateFilters
        :default-status="2"
        @onSendDate="serachReport"
      ></DateFilters>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.amount`]="{ item }">
          <span
            class="font-weight-semibold"
            :class="item.type == $t('income') ? 'success--text' : item.type == $t('expenditure') ? 'error--text' : 'primary--text'"
          >
            {{ item.amount }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiFileExcelOutline } from '@mdi/js'
import Comeback from '../Comeback.vue'
import { i18n } from '@/plugins/i18n'
import DateFilters from '@/components/basicComponents/DateFilters.vue'
import { reportProfit, removeComma } from '../useExcel'
import { sumdate } from '@/plugins/filters'

export default {
  components: {
    Comeback,
    DateFilters,
  },
  setup() {
    const XLSX = require('xlsx')
    const columns = ref([
      { text: '#', value: 'number', width: 50 },
      { text: i18n.t('list'), value: 'list', align: 'left font-weight-bold' },
      { text: i18n.t('firstname_lastname'), value: 'user_name' },
      { text: i18n.t('type'), value: 'type', width: 100 },
      {
        text: i18n.t('cost_amount'), value: 'amount', width: 250, align: 'end',
      },
    ])
    const dataTableList = ref([])
    const options = ref({})
    const loading = ref(true)
    const header = ref('')
    const exportLoading = ref(false)

    const serachReport = async (start, end) => {
      loading.value = true
      header.value = `${sumdate(start)} ${i18n.t(
        'report_profit_loss_summary',
      )} ${sumdate(end)}.xlsx`
      const data = await reportProfit({ start, end })
      dataTableList.value = data // await printExcel.removeComma( JSON.parse(JSON.stringify(data))  ) ;
      options.value.itemsPerPage = -1
      loading.value = false
    }

    const exportExcel = async () => {
      exportLoading.value = true
      const dataExport = await removeComma(JSON.parse(JSON.stringify(dataTableList.value)))
      const fileName = header.value

      // เมื่อกดปุ่มจะทำการสร้างไฟล์ xcel ด้วย xlsx
      /*  รายงานวิเคราะห์รายการตรวจ */
      const Heading = [
        [`${header.value}`],
        [
          '#',
          i18n.t('list'),
          i18n.t('firstname_lastname'),
          i18n.t('type'),
          i18n.t('cost_amount'),
        ],
      ]
      const ws = XLSX.utils.aoa_to_sheet(Heading)
      XLSX.utils.sheet_add_json(ws, dataExport, {
        header: ['number', 'list', 'user_name', 'type', 'amount'],
        skipHeader: true,
        origin: -1,
      })
      const wb = XLSX.utils.book_new()
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 4 } }]
      const wscols = [
        { wch: 6 },
        { wch: 25 },
        { wch: 20 },
        { wch: 10 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ]
      ws['!merges'] = merge
      ws['!cols'] = wscols
      XLSX.utils.book_append_sheet(wb, ws, 'sheet')

      /* พิมร์ files */
      XLSX.writeFile(wb, fileName)
      setTimeout(() => {
        exportLoading.value = false
      }, 1500)
    }

    return {
      serachReport,
      columns,
      dataTableList,
      options,
      loading,
      exportLoading,
      exportExcel,
      mdiFileExcelOutline,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
